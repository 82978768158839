import { Box, Divider } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CategoriesContainer from "../../../components/app/Categories/CategoriesContainer";
import ImageCarousel from "../../../components/common/ImageCarousel";
import ProductsContainer from "../../../components/app/Products/ProductsContainer";
import ProductsAPI from "../../../api/firebase/ProductsAPI";

import slide1 from "./../../../assets/images/slider/ganesh-chaturthi.webp";
// import slide2 from "./../../../assets/images/slider/slide-2.webp";
// import slide3 from "./../../../assets/images/slider/slide-3.webp";
// import slide4 from "./../../../assets/images/slider/slide-4.webp";
// import slide5 from "./../../../assets/images/slider/slide-5.webp";
// import slide6 from "./../../../assets/images/slider/slide-6.webp";
// import slide7 from "./../../../assets/images/slider/slide-7.webp";
// import slide8 from "./../../../assets/images/slider/slide-8.webp";
// import slide9 from "./../../../assets/images/slider/slide-9.webp";
// import slide10 from "./../../../assets/images/slider/slide-10.webp";
// import slide11 from "./../../../assets/images/slider/slide-11.webp";
import { useLoaderData } from "react-router-dom";
import AllProductContext from "../../../context/allProducts/AllProductsContext";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import ReviewsAPI from "../../../api/firebase/ReviewsAPI";
import AllReviewsContext from "../../../context/allReviews/AllReviewsContext";

const Homepage = () => {
  const sliderImagesArr = [
    slide1,
    // slide2,
    // slide3,
    // slide4,
    // slide5,
    // slide6,
    // slide7,
    // slide8,
    // slide9,
    // slide10,
    // slide11,
  ];
  const allProductsContext = useContext(AllProductContext);
  const allReviewsContext = useContext(AllReviewsContext);

  const loaderData = useLoaderData();
  const productsArray = loaderData.data;
  const reviewsAPI = new ReviewsAPI();

  //console.log("allReviewsContext:", allReviewsContext.state);

  const allCategoriesContext = useContext(AllCategoriesContext);
  const [allCategoriesArr, setAllCategoriesArr] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  useEffect(() => {
    allProductsContext.setState(productsArray);
    console.log("Products Array:", productsArray);
  }, []);
  useEffect(() => {
    reviewsAPI.getReviews().then((res) => {
      //console.log("getReviews RES:", res);
      if (res.success) {
        allReviewsContext.setState(res.data);
      }
    });
  }, [allProductsContext.state]);

  // Fetch all categories
  useEffect(() => {
    setAllCategoriesArr(allCategoriesContext.state);
  }, [allCategoriesContext.state]);
  useEffect(() => {
    if (allCategoriesArr === undefined) {
      return;
    }
    // console.log("allCategoriesArr:", allCategoriesArr);
    const parentCats = allCategoriesArr.filter((item) => {
      if (item.parentCategory === undefined) return false;
      return item.parentCategory.id === "None";
    });
    setParentCategories(parentCats);
    // console.log("parentCats:", parentCats);
  }, [allCategoriesArr]);

  return (
    <>
      <Box component="main" sx={{ width: "100%" }}>
        <Box className="">
          <ImageCarousel ImagesArr={sliderImagesArr} autoPlay={true} />
        </Box>
        {parentCategories && parentCategories.length > 0 && (
          <CategoriesContainer
            heading="Browse by Categories"
            categories={parentCategories}
            view="RECTANGLE"
          />
        )}
        {parentCategories === undefined ||
          (parentCategories.length == 0 && (
            <h3 className="centered-flex"> No Categories found</h3>
          ))}
        {productsArray && productsArray.length > 0 && (
          <>
            <Divider />
            <ProductsContainer
              heading="Best Rated Products"
              productsArray={productsArray}
              showCarousel={false}
            />
          </>
        )}
        {productsArray === undefined && (
          <h3 className="centered-flex">No products found</h3>
        )}
      </Box>
    </>
  );
};
export const DataLoader = async () => {
  const productAPI = new ProductsAPI();
  const resProducts = productAPI.getProducts();
  return resProducts;
};
export default Homepage;
