import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Typography,
  Grid,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Divider,
  Box,
  Skeleton,
  Modal,
  Backdrop,
  Fade,
  Button,
  Rating,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import ImageCarousel from "../../common/ImageCarousel";
import StarRating from "./StarRating";
import CustomButton from "../../common/Button/CustomButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RecentlyViewedContext from "../../../context/recentlyViewed/RecentlyViewedContext";
import CartItemsContext from "../../../context/cartItems/CartItemsContext";
import AllProductContext from "../../../context/allProducts/AllProductsContext";
import ProductsContainer from "./ProductsContainer";
import LikedItemsContext from "../../../context/likedItems/LikedItemsContext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Link } from "react-router-dom";
import CustomisedSnackbar from "../../common/Snackbar/CustomisedSnackbar";
import Loader from "../../loader/Loader";
import CustomSeparatorBreadcrumb from "../../common/Breadcrumb/CustomSeparatorBreadcrumb";
import CustomerReviews from "../Reviews/CustomerReviews";
import AddReview from "../Reviews/AddReview";
import AllReviewsContext from "../../../context/allReviews/AllReviewsContext";
import UserProfileContext from "../../../context/userProfile/UserProfileContext";
import { Helmet } from "react-helmet";
import FlipkartLogo from "./../../../assets/images/Flipkart-Logo.png";
import AmazonLogo from "./../../../assets/images/Amazon_logo.png";
import Tata1mgLogo from "./../../../assets/images/TATA1mg_logo.png";

const Root = styled("div")({
  flexGrow: 1,
  padding: "1rem",
  marginTop: "1rem",
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};
const Item = styled("div")(({ theme }) => ({
  // backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  // ...theme.typography.body2,
  margin: "0 0.2rem",
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const ProductDetail = ({ product }) => {
  const [open, setOpen] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const handleReviewOpen = () => setOpenReview(true);
  const handleReviewClose = () => setOpenReview(false);
  const [itemCount, setItemCount] = useState(null);
  const addToCartClicked = useRef(false);
  const buttonText = useRef("Add to cart");
  const cartMessage = useRef("");
  const recentlyViewedContext = useContext(RecentlyViewedContext);
  const cartItemsContext = useContext(CartItemsContext);
  const recentlyViewedItems = recentlyViewedContext.state;
  const allProductContext = useContext(AllProductContext);
  const allProductsArray = allProductContext.state;
  const allReviewsContext = useContext(AllReviewsContext);
  const userProfileContext = useContext(UserProfileContext);
  const user = userProfileContext.state;
  const likedItemsContext = useContext(LikedItemsContext);
  const [likedItemsArray, setLikedItemsArray] = useState([]);
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [reviewByUser, setReviewByUser] = useState(null);
  const [productURL, setProductURL] = useState("");
  const [productTitle, setProductTitle] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productImage, setProductImage] = useState("");
  const [proRating, setProRating] = useState({
    avg: 0,
    ratings: [],
    reviews: [],
  });
  //console.log("product:", product);
  let breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/">
      Home
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={"/collections/" + product.parentCategory.id}
    >
      {product.parentCategory.name}
    </Link>,
  ];
  if (product.categories.length > 0) {
    breadcrumbs.push(
      <Link
        underline="hover"
        key="3"
        color="inherit"
        to={"/collections/" + product.categories[0]}
      >
        <span style={{ textTransform: "capitalize" }}>
          {product.categories[0].replace(/_/g, " ").toLowerCase()}
        </span>
      </Link>
    );
  }
  // breadcrumbs.push(
  //   <Typography
  //     key="4"
  //     color="text.primary"
  //     sx={{textTransform: "capitalize"}}
  //     noWrap
  //     maxWidth="100%"
  //   >
  //     {product.id}
  //   </Typography>
  // );

  useEffect(() => {
    // console.log("1. PRODUCT ID CHANGED =====> product:", product);
    const urlOfProduct =
      "https://kalashcart.in/collections/" +
      product.parentCategory.id +
      "/" +
      product.id;
    const titleOfProduct = product.title.substr(0, 65);
    const productDesc = product.description.substr(0, 65);
    const productImage = product.images[0].url;
    setProductURL(urlOfProduct);
    setProductTitle(titleOfProduct);
    setProductDescription(productDesc);
    setProductImage(productImage);
    clearRefs();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    setLiked(false);
    const tempLikedItems = likedItemsContext.state || [];
    setLikedItemsArray(tempLikedItems);
    if (tempLikedItems !== null) {
      const indexOfProductInLikedItems = tempLikedItems.findIndex(
        (a) => a.id === product.id
      );
      if (indexOfProductInLikedItems !== -1) {
        setLiked(true);
      }
    }

    // Get CartState
    const tempCartItems = cartItemsContext.state;
    // console.log("tempCartItems:", tempCartItems);
    // Check if this product already added to cart
    if (tempCartItems !== null && tempCartItems.length > 0) {
      const indexOfProductInCart = tempCartItems.findIndex(
        (a) => a.id === product.id
      );
      // console.log("indexOfProductInCart:", indexOfProductInCart);
      // Update product if already in array
      if (indexOfProductInCart !== -1) {
        addToCartClicked.current = true;
        let tempItemCount = tempCartItems[indexOfProductInCart].quantity;
        setItemCount(tempItemCount);
        if (tempItemCount === 1) {
          buttonText.current = "1 Unit in cart";
        } else {
          buttonText.current = tempItemCount + " units in cart";
          // console.log("buttonText.current:", buttonText.current);
        }
      }
    }
    // Check if product already in RecentlyViewedItems Array
    const indexOfProductInRecentlyViewed = recentlyViewedItems.findIndex(
      (a) => a.id === product.id
    );
    // Remove product if already in array
    if (indexOfProductInRecentlyViewed !== -1) {
      recentlyViewedItems.splice(indexOfProductInRecentlyViewed, 1);
    }
    // Add product at top of the array
    recentlyViewedContext.setState([product, ...recentlyViewedItems]);
    // Check max length of RecentlyViewedItems Array
    if (recentlyViewedItems.length === 10) {
      recentlyViewedItems.pop();
    }
    if (product.ratings && product.ratings.length > 0) {
      const prod_rating_average =
        product.ratings.reduce((a, b) => a + b, 0) / product.ratings.length;
      const rating_average = Math.round(prod_rating_average / 0.5) * 0.5;
      const setData = {
        avg: rating_average,
        ratings: product.ratings,
        reviews: product.reviews ? product.reviews : [],
      };
      //console.log("1. UPDATE PRO RATING====>", setData);
      setProRating({
        ...setData,
      });
    }
    // Check if user has already added review for this produuct
    if (
      user !== undefined &&
      user !== null &&
      user.reviews !== undefined &&
      product.reviews !== undefined
    ) {
      const userReviewsIds = user.reviews || [];
      const productReviewsIds = product.reviews || [];
      const commonId = userReviewsIds.filter((item) =>
        productReviewsIds.includes(item)
      );
      //console.log("1. commonId:", commonId);
      if (commonId.length > 0) {
        const reviewData = allReviewsContext.state.filter((item) => {
          return item.id === commonId[0];
        });
        //console.log("1. SET REVIEW BY USER ====>", reviewData[0]);
        setReviewByUser({ ...reviewData[0] });
      }
    }
  }, [product.id]);

  useEffect(() => {
    // console.log(
    //   "2. ALL PRODUCTS ARRAY OR USER CHANGED =======>",
    //   allProductsArray,
    //   " user:",
    //   user
    // );
    const prod = allProductsArray.filter((item) => item.id === product.id);
    //console.log("2. prod now:", prod[0]);
    if (prod[0].ratings && prod[0].ratings.length > 0) {
      const p_rating_average =
        prod[0].ratings.reduce((a, b) => a + b, 0) / prod[0].ratings.length;
      const rating_average = Math.round(p_rating_average / 0.5) * 0.5;
      const setData = {
        avg: rating_average,
        ratings: prod[0].ratings,
        reviews: prod[0].reviews ? prod[0].reviews : [],
      };
      //console.log("2. UPDATE PRO RATING====>", setData);
      setProRating({
        ...setData,
      });
    }
    if (
      user !== undefined &&
      user !== null &&
      user.reviews !== undefined &&
      product.reviews !== undefined
    ) {
      const userReviewsIds = user.reviews || [];
      const productReviewsIds = product.reviews || [];
      const commonId = userReviewsIds.filter((item) =>
        productReviewsIds.includes(item)
      );
      //console.log("2. commonId:", commonId);
      if (commonId.length > 0) {
        const reviewData = allReviewsContext.state.filter((item) => {
          return item.id === commonId[0];
        });
        //console.log("2. SET REVIEW BY USER ====>", reviewData[0]);
        setReviewByUser({ ...reviewData[0] });
      }
    }
  }, [allProductsArray, user]);

  useEffect(() => {
    // console.log(
    //   "3. ALL REVIEWS CONTEXT OR ALL PRODUCTS ARRAY OR USER CHANGED =====> ",
    //   allReviewsContext.state,
    //   " user:",
    //   user,
    //   " allProductsArray:",
    //   allProductsArray
    // );
    const prod = allProductsArray.filter((item) => item.id === product.id);
    //console.log("3. prod now:", prod[0]);
    // Check if user has already added review for this produuct
    if (
      user !== undefined &&
      user !== null &&
      user.reviews !== undefined &&
      prod[0].reviews !== undefined
    ) {
      const userReviewsIds = user.reviews || [];
      const productReviewsIds = prod[0].reviews || [];
      // console.log(
      //   "3. userReviewsIds:",
      //   userReviewsIds,
      //   " productReviewsIds:",
      //   productReviewsIds
      // );

      const commonId = userReviewsIds.filter((item) =>
        productReviewsIds.includes(item)
      );
      //console.log("3. commonId:", commonId);
      if (commonId.length > 0) {
        const reviewData = allReviewsContext.state.filter((item) => {
          return item.id === commonId[0];
        });
        //console.log("3. SET REVIEW BY USER====>", reviewData[0]);
        setReviewByUser({ ...reviewData[0] });
      }
    }
  }, [allReviewsContext.state, user, allProductsArray]);

  useEffect(() => {
    //console.log("PRO RATING CHANGED =======> ", proRating);
  }, [proRating]);
  useEffect(() => {
    //console.log("REVIEW BY USER CHANGED =======> ", reviewByUser);
  }, [reviewByUser]);
  useEffect(() => {
    // console.log("itemCount changed:", itemCount);
    if (itemCount !== null) {
      handleCartUpdate();
    }
  }, [itemCount]);
  useEffect(() => {
    if (updating) {
      setUpdating(false);
    }
  }, [cartItemsContext.state]);

  const handleLikeItem = () => {
    const likedItemsArray = likedItemsContext.state;
    if (likedItemsArray !== null) {
      const indexOfProductInLikedItems = likedItemsArray.findIndex(
        (a) => a.id === product.id
      );
      if (indexOfProductInLikedItems !== -1) {
        cartMessage.current = product.title + " removed from wishlist";
        likedItemsArray.splice(indexOfProductInLikedItems, 1);
        likedItemsContext.setState([...likedItemsArray]);
        setLiked(false);
      } else {
        // likedItemsArray.push(product);
        cartMessage.current = product.title + " added to wishlist";
        likedItemsContext.setState([product, ...likedItemsArray]);
        setLiked(true);
      }
    } else {
      cartMessage.current = product.title + " added to wishlist";
      likedItemsContext.setState([product]);
      setLiked(true);
    }
    setOpen(true);
  };
  const clearRefs = () => {
    // console.log("Clear Refs");
    setReviewByUser(null);
    const setData = {
      avg: 0,
      ratings: [],
      reviews: [],
    };
    setProRating({ ...setData });
    setItemCount(null);
    addToCartClicked.current = false;
    buttonText.current = "Add to cart";
    cartMessage.current = "";
    setOpen(false);
    setUpdating(false);
  };
  const handleAddToCart = () => {
    setUpdating(true);
    setTimeout(() => {
      // As this product has been added to cart, Remove it from RecentlyViewedItems Array
      const indexOfProduct = recentlyViewedItems.findIndex(
        (a) => a.id === product.id
      );
      // Remove product if already in array
      if (indexOfProduct !== -1) {
        recentlyViewedItems.splice(indexOfProduct, 1);
      }
      addToCartClicked.current = !addToCartClicked.current;
      setItemCount(1);
      cartMessage.current = "1 unit of " + product.title + " added to cart";
      buttonText.current = "1 Unit in cart";
      setOpen(true);
    }, 200);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMinusIconClick = () => {
    setUpdating(true);
    setTimeout(() => {
      if (itemCount === 1) {
        cartMessage.current = product.title + " removed from cart";
        recentlyViewedContext.setState([product, ...recentlyViewedItems]);
        addToCartClicked.current = false;
        buttonText.current = "Add to cart";
        setItemCount(0);
        setOpen(true);
      } else {
        let tempItemCount = itemCount - 1;
        setItemCount((prevState) => prevState - 1);
        buttonText.current = tempItemCount + " units in cart";
        cartMessage.current =
          "1 unit of " + product.title + " removed from cart";
        setOpen(true);
      }
    }, 200);
  };
  const handlePlusIconClick = () => {
    setUpdating(true);
    setTimeout(() => {
      let tempItemCount = itemCount + 1;
      setItemCount((prevState) => prevState + 1);
      buttonText.current = tempItemCount + " units in cart";
      cartMessage.current =
        "1 more unit of " + product.title + " added to cart";
      setOpen(true);
    }, 200);
  };
  const handleCartUpdate = () => {
    // Check if product already in Cart State
    if (cartItemsContext.state !== null) {
      const indexOfProduct = cartItemsContext.state.findIndex(
        (a) => a.id === product.id
      );
      // Update product if already in array
      if (indexOfProduct !== -1) {
        if (itemCount !== 0) {
          const updatedCartItem = {
            ...cartItemsContext.state[indexOfProduct],
            quantity: itemCount,
          };
          const tempCartItems = cartItemsContext.state;
          tempCartItems.splice(indexOfProduct, 1);

          cartItemsContext.setState((prevState) => [
            updatedCartItem,
            ...tempCartItems,
          ]);
        } else {
          const tempCartItems = cartItemsContext.state;
          // Remove item from cart state
          tempCartItems.splice(indexOfProduct, 1);
          cartItemsContext.setState([...tempCartItems]);
        }
      } else {
        // Add product to the cart state if quantity is greater than 0
        if (itemCount !== 0) {
          cartItemsContext.setState((prevState) => [
            { ...product, quantity: itemCount },
            ...prevState,
          ]);
        }
      }
    } else {
      // Add product to the cart state if quantity is greater than 0
      if (itemCount !== 0) {
        cartItemsContext.setState([{ ...product, quantity: itemCount }]);
      }
    }
  };
  const onCloseReview = () => {
    setOpenReview(false);
  };

  return !loading ? (
    <Root>
      <Helmet>
        <title>{productTitle}</title>
        <meta property="og:url" content={productURL} />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={productTitle} />
        <meta property="og:description" content={productDescription} />
        <meta name="keywords" content={product.searchTerms}></meta>‍
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <ImageCarousel
            showThumbs={true}
            ImagesArr={product.images.map((a) => a.url)}
          />
          <IconButton
            className="icon-float favorite-icon wow fadeIn"
            data-wow-delay="0.25s"
            onClick={() => handleLikeItem()}
            sx={{ top: "40px !important", right: "30px !important" }}
          >
            <FavoriteIcon
              sx={{ width: "1.1em", height: "1.1em" }}
              className={liked ? "favorite-icon-true" : "favorite-icon-false"}
            />
          </IconButton>
        </Grid>
        <Grid item xs={12} md={6} sx={{ paddingTop: "0 !important" }}>
          <Grid
            sx={{ display: { xs: "none", sm: "block" }, marginBottom: "1rem" }}
            item
            xs={12}
            textAlign="right"
          >
            <CustomSeparatorBreadcrumb breadcrumbs={breadcrumbs} />
          </Grid>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="h5"
                className="wow fadeIn"
              >
                {product.title}
              </Typography>
              <StarRating
                rating={proRating.avg}
                showTotalNumber={proRating.ratings.length}
                showLabel={true}
              />
              <Typography className="discount-percent-detail" variant="">
                -
                {Math.ceil(
                  ((product.maxPrice - product.listPrice) / product.maxPrice) *
                    100
                )}
                %
              </Typography>
              <Typography
                variant="body"
                color="primary"
                className="list-price-detail"
              >
                &#8377;{product.listPrice}
              </Typography>
              <Typography variant="" className="mrp-text">
                M.R.P.
              </Typography>

              <Typography className="max-price" variant="">
                &#8377;{product.maxPrice}
              </Typography>
              <Grid container spacing={1} sx={{ marginTop: "1rem" }}>
                <Grid
                  sx={{ display: addToCartClicked.current ? "flex" : "none" }}
                  item
                  xs={4}
                  md={2}
                  className="addItemCount wow fadeInUp"
                >
                  <Item>
                    <Tooltip title="Remove 1 unit" placement="top">
                      <IconButton
                        aria-label="remove unit"
                        onClick={handleMinusIconClick}
                        sx={{ textAlign: "center" }}
                      >
                        <RemoveCircleOutlineIcon sx={{ fontSize: "1.5rem" }} />
                      </IconButton>
                    </Tooltip>
                  </Item>
                  <Item>
                    <Typography variant="string" component="span">
                      {itemCount}
                    </Typography>
                  </Item>
                  <Item>
                    <Tooltip title="Add 1 unit" placement="top">
                      <span>
                        <IconButton
                          aria-label="add unit"
                          disabled={itemCount > product.unitsInStock - 1}
                          onClick={handlePlusIconClick}
                        >
                          <AddCircleOutlineIcon sx={{ fontSize: "1.5rem" }} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </Item>
                </Grid>
                <Grid
                  item
                  xs={addToCartClicked.current ? 8 : 12}
                  md={addToCartClicked.current ? 4 : 6}
                  className="wow fadeInUp"
                  data-wow-delay="0.25s"
                >
                  <CustomButton
                    fullWidth={true}
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => {
                      if (!addToCartClicked.current) {
                        handleAddToCart();
                      }
                    }}
                    startIcon={<AddShoppingCartIcon />}
                    sx={{ paddingLeft: 0 }}
                  >
                    {buttonText.current}
                  </CustomButton>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  className="wow fadeInUp"
                  data-wow-delay="0.25s"
                >
                  <Link to="/cart">
                    <CustomButton
                      fullWidth={true}
                      variant="contained"
                      color="primary"
                      size="large"
                      startIcon={<FlashOnIcon />}
                    >
                      Go to cart
                    </CustomButton>
                  </Link>
                </Grid>
                {((product.flipkartURL !== undefined &&
                  product.flipkartURL !== "") ||
                  (product.amazonURL !== undefined &&
                    product.amazonURL !== "") ||
                  (product.tata1mgURL !== undefined &&
                    product.tata1mgURL !== "")) && (
                  <Grid
                    item
                    xs={12}
                    className="wow fadeInUp"
                    data-wow-delay="0.25s"
                    style={{ marginTop: "1rem" }}
                  >
                    <Typography variant="p">Also available on</Typography>
                  </Grid>
                )}
                <Grid item container justifyContent="space-evenly">
                  {product.flipkartURL !== undefined &&
                    product.flipkartURL !== "" && (
                      <Grid
                        item
                        className="wow fadeInUp"
                        data-wow-delay="0.30s"
                      >
                        <a
                          href={product.flipkartURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          <img
                            src={FlipkartLogo}
                            className="flipkart-icon"
                          ></img>
                        </a>
                      </Grid>
                    )}
                  {product.amazonURL !== undefined &&
                    product.amazonURL !== "" && (
                      <Grid
                        item
                        className="wow fadeInUp"
                        data-wow-delay="0.35s"
                      >
                        <a
                          href={product.amazonURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          <img src={AmazonLogo} className="amazon-icon"></img>
                        </a>
                      </Grid>
                    )}
                  {product.tata1mgURL !== undefined &&
                    product.tata1mgURL !== "" && (
                      <Grid
                        item
                        className="wow fadeInUp"
                        data-wow-delay="0.30s"
                      >
                        <a
                          href={product.tata1mgURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            display: "block",
                            textAlign: "center",
                          }}
                        >
                          <img src={Tata1mgLogo} className="tata1mg-icon"></img>
                        </a>
                      </Grid>
                    )}
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                sx={{ margin: "1rem 0", color: "black" }}
                className="wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <span
                  dangerouslySetInnerHTML={{ __html: product.description }}
                ></span>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <CustomerReviews
            productReviewsData={{ ...proRating }}
            allReviews={allReviewsContext.state}
          />
        </Grid>
        {reviewByUser === null && (
          <Grid item xs={12}>
            <Typography variant="h6">Review this product</Typography>
            <Typography variant="p">
              Share your thoughts with other customers
            </Typography>
          </Grid>
        )}
        {reviewByUser !== null && (
          <Grid item xs={12}>
            <Typography variant="h5">
              You have rated <Rating value={reviewByUser.rating} readOnly />
            </Typography>
            <Typography variant="h6">{reviewByUser.headline}</Typography>
            <Typography variant="p">{reviewByUser.detail}</Typography>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <CustomButton
            onClick={handleReviewOpen}
            fullWidth={true}
            size="small"
            variant="outlined"
          >
            {reviewByUser === null ? "Add Review" : "Edit Review"}
          </CustomButton>
        </Grid>
      </Grid>
      {product.categories.map((categoryId) => {
        const tempProducts = allProductsArray.filter((item) => {
          return item.categories.includes(categoryId) && item.id !== product.id;
        });
        return (
          tempProducts.length > 0 && (
            <Grid
              container
              spacing={2}
              key={categoryId}
              sx={{ marginTop: "1rem" }}
            >
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <ProductsContainer
                  heading={`More ${categoryId.replace(/_/g, " ")}`}
                  showInRow={true}
                  addToCartEnable={true}
                  showCarousel={false}
                  productsArray={tempProducts}
                  showRatingNumber={false}
                />
              </Grid>
            </Grid>
          )
        );
      })}

      {/* likedItemsArray */}
      {likedItemsArray.length > 0 && (
        <Grid container spacing={2} sx={{ marginTop: "1rem" }}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ProductsContainer
              heading={`Products you have Liked`}
              showInRow={true}
              addToCartEnable={true}
              showCarousel={false}
              productsArray={likedItemsArray}
              fadeOutOnRemovingLike={true}
              showRatingNumber={false}
            />
          </Grid>
        </Grid>
      )}
      <CustomisedSnackbar
        message={cartMessage.current}
        severity="success"
        open={open}
        handleClose={handleClose}
      />
      {updating && <Loader />}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openReview}
        onClose={handleReviewClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openReview}>
          <Box sx={style}>
            <AddReview
              product={product}
              reviewData={reviewByUser}
              handleClose={onCloseReview}
            />
          </Box>
        </Fade>
      </Modal>
    </Root>
  ) : (
    <Root>
      <Helmet>
        <title>{productTitle}</title>
        <meta property="og:url" content={productURL} />
        <meta property="og:type" content="product" />
        <meta property="og:title" content={productTitle} />
        <meta property="og:description" content={productDescription} />
        <meta name="keywords" content={product.searchTerms}></meta>‍
      </Helmet>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} textAlign="right">
          <Skeleton width="50%" />
        </Grid> */}
        <Grid item container spacing={1} xs={12} sm={6}>
          <Grid item xs={12}>
            <Box className="product-box-container">
              <Skeleton variant="rectangular" animation="wave" height="75vh" />
            </Box>
          </Grid>
          <Grid
            item
            container
            spacing={1}
            direction="row"
            justifyContent="center"
          >
            <Grid item xs={3}>
              <Box className="product-box-container">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="10vh"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="product-box-container">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="10vh"
                />
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className="product-box-container">
                <Skeleton
                  variant="rectangular"
                  animation="wave"
                  height="10vh"
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box className="product-box-container">
            <Skeleton variant="rectangular" animation="wave" height="75vh" />
            <Skeleton variant="text" animation="wave" height="5vh" />
            <Skeleton variant="rectangular" animation="wave" height="5vh" />
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
};

export default ProductDetail;
