import { Box, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PlaceholderImg from "../../../assets/images/placeholder/category.jpg";
import { Skeleton } from "@mui/material";

const CategoryBox = ({
  title,
  imgSrc = PlaceholderImg,
  link,
  view,
  inSearchResult = false,
  skeletonOnly = false,
}) => {
  const [imgsLoaded, setImgsLoaded] = useState(false);
  useEffect(() => {
    const loadImage = (image) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        // wait 1 second to simulate loading time
        loadImg.onload = () =>
          // setTimeout(() => {
          resolve(image);
        // }, 100);

        loadImg.onerror = (err) => reject(err);
      });
    };

    if (!skeletonOnly) {
      if (imgSrc === "") {
        loadImage(PlaceholderImg)
          .then(() => setImgsLoaded(true))
          .catch((err) => console.log("Failed to load images", err));
      } else {
      }
      loadImage(imgSrc)
        .then(() => setImgsLoaded(true))
        .catch((err) => console.log("Failed to load images", err));
    }
  });
  return (
    <Box
      className={`category-box-container  ${
        view === "CIRCULAR" ? "circular-box" : ""
      } ${inSearchResult ? "circular-box-search-result" : ""}`}
    >
      <Box
        className={`category-img-container ${
          view === "CIRCULAR" ? "circular-img" : ""
        }`}
      >
        <Link to={link}>
          {(!imgsLoaded || skeletonOnly) && (
            <Box>
              <Skeleton
                animation="wave"
                variant={view === "CIRCULAR" ? "circular" : "rounded"}
                sx={{ paddingBottom: "100%" }}
              />
            </Box>
          )}
          {imgsLoaded && !skeletonOnly && (
            <Box
              className="category-paper-container"
              sx={{
                backgroundImage: `url(${
                  imgSrc !== "" ? imgSrc : PlaceholderImg
                })`,
              }}
            ></Box>
          )}
        </Link>
      </Box>

      <Paper className="paper-heading" sx={{ background: "transparent" }}>
        <Typography
          className="text"
          variant="body2"
          sx={{ fontSize: inSearchResult ? "0.75rem" : "" }}
          noWrap
        >
          {!skeletonOnly && title}
          {skeletonOnly && <Skeleton animation="wave" />}
        </Typography>
      </Paper>
    </Box>
  );
};

export default CategoryBox;
