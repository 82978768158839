import { Stack, Typography } from "@mui/material";
import React from "react";
import ScrollToHide from "./appbar/ScrollToHide";
import IconButton from "@mui/material/IconButton";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
// import {Carousel} from "@trendyol-js/react-carousel";
import Carousel from "react-material-ui-carousel";
const SaleBanner = ({ isMobile }) => {
  // console.log("isMobile:", isMobile);
  const texts = ["Free Shipping above Rs. 499", "100% Made in India products"];

  if (isMobile) {
    return (
      <Carousel
        autoPlay={true}
        animation="slide"
        indicators={false}
        interval={3000}
        navButtonsAlwaysInvisible={true}
        sx={{
          backgroundColor: process.env.REACT_APP_BG_COLOR_LIGHT,
          color: process.env.REACT_APP_FONT_COLOR_DARK,
          padding: "2px 0",
        }}
      >
        {texts.map((text, index) => {
          return (
            <div style={{ textAlign: "center" }} key={index}>
              <Typography
                variant="caption"
                className="salebar-caption"
                sx={{ fontWeight: "500 !important" }}
              >
                {text}
              </Typography>
            </div>
          );
        })}
      </Carousel>
    );
  } else {
    return (
      <ScrollToHide threshold={20}>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          spacing={5}
          style={{
            backgroundColor: process.env.REACT_APP_BG_COLOR_LIGHT,
            color: process.env.REACT_APP_FONT_COLOR_DARK,
            padding: "5px 2rem",
          }}
        >
          <Typography variant="caption" className="salebar-caption">
            Free Shipping above Rs. 499
          </Typography>

          <Typography variant="caption" className="salebar-caption">
            100% Made in India products
          </Typography>
          <div>
            <IconButton
              aria-label="facebook"
              className="salebar-caption icon-button"
              disableTouchRipple
              disableFocusRipple
              disableRipple
              onClick={() => {
                window.location.href =
                  "https://www.facebook.com/profile.php?id=61559031269996";
              }}
            >
              <FacebookIcon className="salebar-caption icon fb" />
            </IconButton>

            <IconButton
              aria-label="instagram"
              className="salebar-caption icon-button"
              disableTouchRipple
              disableFocusRipple
              disableRipple
              onClick={() => {
                window.location.href = "https://www.instagram.com/kalashcart/";
              }}
            >
              <InstagramIcon className="salebar-caption icon insta" />
            </IconButton>
            {/* <IconButton
              aria-label="linkedin"
              className="salebar-caption icon-button"
              disableTouchRipple
              disableFocusRipple
              disableRipple
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/in/inprovedahealthcare-products-b36a6037/";
              }}
            >
              <LinkedInIcon className="salebar-caption icon linkedin" />
            </IconButton> */}
            <IconButton
              aria-label="whatsapp"
              className="salebar-caption icon-button"
              disableTouchRipple
              disableFocusRipple
              disableRipple
              onClick={() => {
                window.location.href =
                  "https://api.whatsapp.com/send/?phone=916398926018";
              }}
            >
              <WhatsAppIcon className="salebar-caption icon whatsapp" />
            </IconButton>
          </div>
        </Stack>
      </ScrollToHide>
    );
  }
};

export default SaleBanner;
