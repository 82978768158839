import React, { useContext, useEffect, useState } from "react";
import { Link, useLoaderData, useParams } from "react-router-dom";
import ProductDetail from "../../../components/app/Products/ProductDetail";
import AllProductContext from "../../../context/allProducts/AllProductsContext";
import NoContent from "../../errors/NoContent";
import ProductsContainer from "../../../components/app/Products/ProductsContainer";
import AllCategoriesContext from "../../../context/allCategories/AllCategoriesContext";
import CategoriesContainer from "../../../components/app/Categories/CategoriesContainer";
import ProductFilters from "../../../components/app/Products/ProductFilters";
import { Box, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";
import AllReviewsContext from "../../../context/allReviews/AllReviewsContext";
import ReviewsAPI from "../../../api/firebase/ReviewsAPI";
const CollectionsPage = () => {
  const loaderData = useLoaderData();
  const productsArray = loaderData.data;
  const allProductsContext = useContext(AllProductContext);
  const allCategoriesContext = useContext(AllCategoriesContext);
  const allReviewsContext = useContext(AllReviewsContext);
  const [allCategoriesArr, setAllCategoriesArr] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [topCategories, setTopCategories] = useState([]);
  const { categoryId, itemId } = useParams();
  const [filteredProductsArr, setFilteredProductsArr] = useState([]);
  const [proPriceRange, setProPriceRange] = useState({
    min: 0,
    max: 0,
  });
  // console.log("categoryId:", categoryId, "itemId:", itemId);
  let breadcrumbs = null;
  let parentCategories = [];
  const basePath = "/collections";

  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
  const reviewsAPI = new ReviewsAPI();
  useEffect(() => {
    reviewsAPI.getReviews().then((res) => {
      //console.log("getReviews RES:", res);
      if (res.success) {
        allReviewsContext.setState(res.data);
      }
    });
  }, []);

  useEffect(() => {
    // console.log(
    //   "CATEGORY ID CHANGED ====> categoryId:",
    //   categoryId,
    //   " productsArray in Collections:",
    //   productsArray
    // );
    allProductsContext.setState(productsArray);
    if (categoryId !== undefined) {
      // Get all products of this category
      const filteredArray = productsArray.filter((pro) => {
        return (
          pro.categories.includes(categoryId) ||
          pro.parentCategory.id === categoryId
        );
      });
      let minP = 0;
      let maxP = 0;
      filteredArray.forEach((pro) => {
        let proPrice = Number(pro.listPrice);
        if (minP === 0 && maxP === 0) {
          minP = proPrice;
          maxP = proPrice;
        } else {
          if (proPrice < minP) {
            minP = proPrice;
          } else if (proPrice > minP && proPrice > maxP) {
            maxP = proPrice;
          }
        }
      });

      setProPriceRange({
        min: minP,
        max: maxP,
      });
      setFilteredProductsArr([...filteredArray]);
    }
  }, [categoryId]);
  useEffect(() => {
    if (allCategoriesContext.state.length > 0) {
      setAllCategoriesArr(allCategoriesContext.state);
    }
  }, [allCategoriesContext.state]);
  useEffect(() => {
    if (allCategoriesArr.length > 0) {
      setIsLoading(false);
    }
    const parentCats = allCategoriesArr.filter((item) => {
      if (item.parentCategory === undefined) {
        return false;
      }
      return item.parentCategory.id === "None";
    });
    setTopCategories(parentCats);
  }, [allCategoriesArr]);

  const setSelectedFilters = (filters) => {
    // console.log("Filters:", filters);
    // Get all products of this category
    const filteredByCategoryId = productsArray.filter((pro) => {
      return (
        pro.categories.includes(categoryId) ||
        pro.parentCategory.id === categoryId
      );
    });
    //console.log("filteredByCategoryId:", filteredByCategoryId);
    if (filters.length === 0) {
      setFilteredProductsArr(filteredByCategoryId);
      return;
    }
    let _filteredArr = [];
    filters.forEach((filter) => {
      if (_filteredArr.length === 0) {
        _filteredArr = filteredByCategoryId;
      }
      switch (filter.type) {
        case "PRICE": {
          //Fetch products which have price in between range
          let _minPrice = filter.value.gt;
          let _maxPrice = filter.value.lt;
          //console.log("filter By PRICE:", _minPrice, " - ", _maxPrice);
          _filteredArr = _filteredArr.filter((pro) => {
            return (
              Number(pro.listPrice) >= Number(_minPrice) &&
              Number(pro.listPrice) <= Number(_maxPrice)
            );
          });
          break;
        }
        case "RATING": {
          // Fetch products which have rating greater than
          //console.log("filter By Rating:", filter.value);
          //console.log("before_filteredArr:", _filteredArr);

          _filteredArr = _filteredArr.filter((pro) => {
            if (pro.ratings === undefined) {
              return false;
            }
            // const prod_rating_average =
            //   pro.ratings.reduce((a, b) => a + b, 0) / pro.ratings.length;
            const p_rating_average =
              pro.ratings.reduce((a, b) => a + b, 0) / pro.ratings.length;
            const rating_average = Math.round(p_rating_average / 0.5) * 0.5;
            // console.log(
            //   "pro:",
            //   pro.id,
            //   " rating:",
            //   Number(p_rating_average),
            //   " avg rating:",
            //   Number(rating_average)
            // );

            return Number(rating_average) >= Number(filter.value);
          });
          // console.log("after_filteredArr:", _filteredArr);

          break;
        }
      }
      setFilteredProductsArr([..._filteredArr]);
    });
  };
  //console.log("Prod Arr:", productsArray, " itemId:", itemId);
  if (itemId !== undefined) {
    // Get Product Detail
    const productDetail = productsArray.find((e) => e.id === itemId);
    // console.log(
    //   "productsArray in collection:",
    //   productsArray,
    //   " itemId:",
    //   itemId,
    //   " productDetail:",
    //   productDetail
    // );
    if (productDetail !== undefined) {
      return !isLoading && <ProductDetail product={productDetail} />;
    } else {
      return !isLoading && <NoContent />;
    }
  } else if (categoryId !== undefined) {
    if (breadcrumbs !== null) {
      breadcrumbs = null;
    }

    // Get category detail
    const categoryDetail = allCategoriesArr.filter((item) => {
      return item.id === categoryId;
    });
    const getParentCategory = (_category) => {
      if (_category.parentCategory.id !== "None") {
        const pCat = allCategoriesArr.filter((item) => {
          return item.id === _category.parentCategory.id;
        });
        parentCategories.push(pCat[0]);
        getParentCategory(pCat[0]);
      }
    };

    if (categoryDetail.length > 0) {
      getParentCategory(categoryDetail[0]);
      parentCategories.reverse();
      if (breadcrumbs === null) {
        breadcrumbs = [
          <Link underline="hover" key="1" color="inherit" to="/">
            Home
          </Link>,
          <Link underline="hover" key="2" color="inherit" to={basePath}>
            Collections
          </Link>,
        ];
        parentCategories.forEach((cat, index) => {
          let pathTo = `${basePath}/${cat.id}`;
          breadcrumbs.push(
            <Link underline="hover" key={index + 3} color="inherit" to={pathTo}>
              {cat.name}
            </Link>
          );
        });
        breadcrumbs.push(
          <Typography key={parentCategories.length + 3} color="text.primary">
            {categoryDetail[0].name}
          </Typography>
        );
      }
    }

    const subCategories = allCategoriesArr.filter((item) => {
      return (
        item.parentCategory.id === categoryId && item.showInNavbar === true
      );
    });

    // console.log("subCategories:", subCategories);
    if (subCategories.length > 0) {
      return (
        !isLoading && (
          <div className="full-height">
            <CategoriesContainer
              heading="Browse by Categries"
              categories={subCategories}
              view="CIRCULAR"
            />
          </div>
        )
      );
    } else {
      // if (filteredProductsArr.length > 0) {
      const _category = categoryId.replace(/_/g, " ");
      const _heading = _category;
      if (!isLoading && !mobileScreen) {
        return (
          <Grid container>
            <Grid item sm={2}>
              <ProductFilters
                initPriceRange={proPriceRange}
                onSelectFilters={setSelectedFilters}
              />
            </Grid>
            <Grid item sm={10}>
              <div className="full-height">
                <ProductsContainer
                  breadcrumbs={breadcrumbs}
                  heading={_heading}
                  productsArray={filteredProductsArr}
                  showCarousel={false}
                  addToCartEnable={true}
                />
              </div>
            </Grid>
          </Grid>
        );
      } else if (!isLoading && mobileScreen) {
        return (
          <Grid container>
            <Grid item xs={12}>
              <div className="full-height">
                <ProductFilters
                  initPriceRange={proPriceRange}
                  onSelectFilters={setSelectedFilters}
                />
                <ProductsContainer
                  heading={_heading}
                  productsArray={filteredProductsArr}
                  showCarousel={false}
                  addToCartEnable={true}
                />
              </div>
            </Grid>
          </Grid>
        );
      }

      // } else {
      //   return (
      //     !isLoading && (
      //       <div className="full-height">
      //         <NoContent />
      //       </div>
      //     )
      //   );
      // }
    }
  } else {
    return (
      <div className="full-height">
        <Box component="main" sx={{ width: "100%" }}>
          <CategoriesContainer
            heading="Browse by Categories"
            categories={topCategories}
            view="RECTANGLE"
          />
        </Box>
      </div>
    );
  }
};
export default CollectionsPage;
