import {
  doc,
  serverTimestamp,
  setDoc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
const collection_name = "products";

export default class ProductsAPI {
  // Add/Update Product
  async setProduct(data, updateTime = true) {
    // console.log(
    //   "API CALL =========> set Product--add form data:",
    //   data.payload,
    //   " to id:",
    //   data.id
    // );
    const payloadData = updateTime ? { createdAt: serverTimestamp() } : {};
    try {
      await setDoc(
        doc(db, collection_name, data.id),
        { ...data.payload, ...payloadData },
        {
          merge: true,
        }
      );
      return { success: true };
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }

  // Add/Update Category
  async setCategory(data) {
    // console.log(
    //   "API CALL =========> add category form data:",
    //   data.payload,
    //   " to id:",
    //   data.id
    // );
    try {
      await setDoc(
        doc(db, "categories", data.id),
        { ...data.payload, createdAt: serverTimestamp() },
        {
          merge: true,
        }
      );
      return { success: true };
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }

  // Fetch all products
  async getProducts() {
    try {
      // console.log("API CALL =========> getProducts:");
      const q = query(
        collection(db, collection_name),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      let proArr = [];
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        proArr.push({ id: doc.id, ...doc.data() });
      });
      console.log("products:", proArr);
      return { success: true, data: proArr };
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }
  // Fetch product by id
  async getProductById(prodId) {
    try {
      // console.log("API CALL =========> getProduct by id:", prodId);
      const docRef = doc(db, collection_name, prodId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        // console.log("product data:", docSnap.data());
        return { success: true, data: docSnap.data() };
      } else {
        // doc.data() will be undefined in this case
        // console.log("No such document!");
        return { success: false, message: "No Product Found" };
      }
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }
  // Fetch all categories
  async getCategories() {
    try {
      // console.log("API CALL =========> getCategories:");

      ///////////// Get data once
      const q = query(collection(db, "categories"), orderBy("name"));

      const querySnapshot = await getDocs(q);
      let catArr = [];
      querySnapshot.forEach((doc) => {
        // console.log("API:", doc);
        // doc.data() is never undefined for query doc snapshots
        catArr.push({ id: doc.id, ...doc.data() });
      });
      return { success: true, data: catArr };
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }

  async deleteProduct(prodId) {
    try {
      await deleteDoc(doc(db, collection_name, prodId));
      return { success: true };
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }
  async deleteCategory(catId) {
    try {
      await deleteDoc(doc(db, "categories", catId));
      return { success: true };
    } catch (ex) {
      console.log(ex);
      return { success: false, message: ex };
    }
  }
}
