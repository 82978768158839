import { Container, Typography } from "@mui/material";
import React from "react";

const RefundPolicy = () => {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" mt="2rem">
        Refund Policy
      </Typography>
      <Typography variant="body1" mt="1rem" fontWeight="500">
        Thank you for choosing to shop with us! We appreciate your business and
        strive to provide you with the best products and service possible.
        Please read our refund policy carefully before making a purchase:
      </Typography>
      <Typography variant="h5" mt="2rem" fontWeight="500">
        Non-Returnable Items
      </Typography>
      <Typography variant="body1" mt="1rem">
        We understand that sometimes a product may not meet your expectations or
        requirements. However, please note that all sales are final, and we do
        not accept returns or exchanges on any products unless the item is
        damaged or defective upon arrival.
      </Typography>
      <Typography variant="h5" mt="2rem" fontWeight="500">
        Damaged or Defective Items
      </Typography>
      <Typography variant="body1" mt="1rem">
        If you receive a damaged or defective item, please contact us within 2
        days of receiving your order. We will require proof of the damage or
        defect, such as clear photographs of the product and packaging. Once we
        verify the issue, we will provide you with instructions for returning
        the item for a replacement or refund.
      </Typography>

      <Typography variant="h6" mt="1rem">
        Refund Process
      </Typography>
      <Typography variant="h6" mt="1rem">
        1. Initiation
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        To initiate a refund request, please send an email at
        support@kalashcart.in with attachments of product images and videos
        within 2 days of receiving your order or discovering the issue.
      </Typography>
      <Typography variant="h6" mt="1rem">
        2. Refund Processing
      </Typography>
      <Typography variant="body1" mt="1rem" pl="2rem">
        Once we receive the refund request and verify its eligibility for a
        refund, we will initiate the refund process within 7 working days.
        <p>
          Refunds will be issued using the original payment method used for the
          purchase, unless mutually agreed upon otherwise.
        </p>
      </Typography>
      <Typography variant="h5" mt="1rem" fontWeight="500">
        Cancellation and Order Changes
      </Typography>
      <Typography variant="body1" mt="1rem">
        <Typography variant="body1" fontWeight="500" component="span" mr="4px">
          1. Order Cancellation:
        </Typography>
        If you wish to cancel your order before it has been shipped, please
        contact our customer support as soon as possible. We will make every
        effort to accommodate your request. However, if the order has already
        been shipped, the refund process will apply.
      </Typography>
      <Typography variant="body1" mt="1rem">
        <Typography variant="body1" fontWeight="500" component="span" mr="4px">
          2. Order Changes:
        </Typography>
        If you need to make changes to your order, such as modifying the
        shipping address or adding/removing items, please contact our customer
        support promptly. We will do our best to accommodate your request.
        However, please note that order changes may not be possible if the order
        has already been processed or shipped.
      </Typography>
      <Typography variant="body1" mt="1rem">
        If you have any questions, concerns, or require assistance with our
        refund policy, please contact our customer support team at{" "}
        <Typography variant="body1" fontWeight="500" component="span" mr="4px">
          support@kalashcart.in
        </Typography>
        or directly call at{" "}
        <Typography variant="body1" fontWeight="500" component="span" mr="4px">
          +91-6398926018.
        </Typography>
        <p>
          We are available to assist you during our business hours 9 AM to 7 PM
          (All 7 days of week).
        </p>
      </Typography>
      <Typography variant="body1" mt="1rem">
        By making a purchase on our website, you acknowledge that you have read,
        understood, and agreed to our refund policy.
      </Typography>
    </Container>
  );
};

export default RefundPolicy;
